import React, { useState } from "react"
import { navigate } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { login } from "../utils/auth"

export default function LoginPage() {
  const [isSubmitting, setIsSubmitting] = useState(false)

  return (
    <Layout>
      <SEO title="Login" />
      <h2>Login</h2>
      <form
        name="login"
        method="post"
        style={styles.form}
        onSubmit={async event => {
          event.stopPropagation()
          event.preventDefault()

          const {
            email: { value: email },
            password: { value: password },
          } = event.target.elements

          setIsSubmitting(true)

          const user = await login({ email, password })

          if (user) {
            navigate("/consulting")
          }

          setIsSubmitting(false)
        }}
      >
        <input type="hidden" name="bot-field" />
        <label style={styles.row}>
          Email
          <input type="email" name="email" id="email" disabled={isSubmitting} />
        </label>
        <label style={styles.row}>
          Password
          <input
            type="password"
            name="password"
            id="password"
            disabled={isSubmitting}
          />
        </label>
        <button type="submit" style={styles.submit} disabled={isSubmitting}>
          Submit
        </button>
      </form>
    </Layout>
  )
}

const styles = {
  form: {
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
  },
  submit: {
    borderRadius: "50px",
    padding: "0.2rem 1rem",
    alignSelf: "flex-end",
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: `"SFMono-Regular", Consolas, "Roboto Mono", "Droid Sans Mono",
    "Liberation Mono", Menlo, Courier, monospace`,
  },
}
